'use strict'
import Vue from 'vue'
// import axios from 'axios'
let axios = require('axios')

/*const defaultParameters = {}
let params = Object.assign({}, defaultParameters, parameters)
return Vue.axios.get(URL, {
  headers: {'Locale': locale},
  params,
  paramsSerializer: paramsSerializerQs
}) */


// test usage in DataTable.vue
/* getTableData() {
    var inParams= {periodtype: this.inPeriodtype,
            trainingtype: this.inTraingintype}              
    getData(this.inURL,inParams) */

export function getData(inURL, inParams) {
    // var retarr = []
    if (!inURL) return
    var sendProgram = inParams || {}
    sendProgram.gextoken = localStorage.getItem('gextoken') || ''
    return axios.get(process.env.VUE_APP_DATA_BASE_URL + inURL, {
        params: { filter: sendProgram },
        responseType: "json"
    })
}

export async function getTableData(inURL, inParams) {
    const response = await getData(inURL, inParams);
    console.info("ODRS gTD response: ", response.data.response);
    return response.data.response;
}

export async function getTableDataSQL(inURL, inParams) {
    const response = await getData(inURL, inParams);
    console.info("ODRS gTDSQL response: ", response.data.items[0]);
    return response.data.items[0][process.env.VUE_APP_STAT_PROC];
}


export function postRow(inURL, inParams) {
    return axios.post(process.env.VUE_APP_DATA_BASE_URL + inURL, inParams, {})
}

export async function runPostRow(inURL, inParams) {
    var sendParams =inParams || {}
    sendParams.gextoken = localStorage.getItem('gextoken')
    //Object.assign(sendParams, {'key3': "value3"})
    console.info("ODRS rPR post: ", sendParams);
    const response = await postRow(inURL, sendParams);
    console.info("ODRS rPR resp: ", response);
    return response.data.rbody[0];
}
export async function runPost(inParams) {
    console.info("ODRS rPO input: ", inParams);
    var sendParams=inParams || {}
    sendParams.gextoken = localStorage.getItem('gextoken')
    sendParams.marketid = process.env.VUE_APP_MARKETID 
    console.info("ODRS rPO post: ", sendParams);
    const response =  await postRow("/exercises/trainingtypes", sendParams);
    console.info("ODRS rPO resp: ", response);
    return response.data.rbody[0];
}

export async function gexLogin(inParams) {
    var sendParams = {}
    sendParams.formLogin = inParams
    sendParams.formLogin.marketid= process.env.VUE_APP_MARKETID 
    const response = await runPostRow("/exercises/trainingtypes", sendParams)
    if (response.status != 200) {
        localStorage.removeItem('gexToken') // if the request fails, remove any possible user token if possible
        return 400;
    }
    const gexToken = response.gextoken 
    localStorage.setItem('gextoken', gexToken) // store the token in localstorage
    console.info("ODRS gexL gexToken: ", gexToken);
    return response;
}

export async function gexLogout() {
    var sendParams = {formLogout:{}}
    sendParams.formLogout.gexToken = localStorage.getItem('gextoken') 
    const response = await runPostRow("/exercises/trainingtypes", sendParams)
    console.info("ODRS geL json: ", response);
    if (response.status != 200) {
        return 400;
    }
    localStorage.removeItem('gextoken')
    return response;
}

export function formatHeaders(inArray) {
    var ra = []
    //inArray.forEach(element => {
    for (var i = 0, len = inArray.length; i < len; i++) {
        const retrow = {}
        const colname = inArray[i]
        retrow.value = colname
        const colcommand = String
        const inputcommand = String
        if (colname.substr(0, 1) === "x") {
            colcommand = colname.substr(1, 1)
            inputcommand = colname.substr(2, 1)
            colname = colname.substr(3).replace("_", " ")
        }
        // if (colcommand === 'h') continue
        retrow.sortable = inputcommand === 's' ? true : false
        retrow.text =  colname // inputcommand === 'r' ? 'del' : colname //.toUpperCase()
        if (['d', 'r', 'e'].indexOf(colcommand) >= 0) retrow.align = 'right'
        else retrow.align = 'left'
        if(inputcommand ==="r") retrow.btn="del"
        retrow.class = colcommand === "d" ? "text-xs-right" : "text-xs-left"
        ra.push(retrow);
    }
    return ra;
}

export default {
    getData,
    getTableData,
    runPostRow,
    formatHeaders,
    gexLogin
}
